body {
  background-color: #fcfcfc;
}

body {
  background-color: #fff;
}
.main.container {
  margin-top: 2em;
}

.cover-container {
  height: 150px;
  background-color: #9e9e9e;
  background-position: center;

  &.small-cover {
    height: 150px;
  }
}

.ui.menu.main-menu {
  border: none;
  box-shadow: none;
  border-radius: .28571429rem .28571429rem 0 0;

  //transition: box-shadow 0.5s ease, padding 0.5s ease;
  //background-color: rgba(255, 255, 255, 0.85);
  //margin-top: -74px;
  //margin-bottom: 0px;



  .item img.logo {
    width: 80px;
    margin-right: 10px;
  }
  .item .logo-brand {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.1em;
    letter-spacing: 1.4px;
  }

  &.fixed {
    img.logo {
      width: 60px;
      margin-right: 0;
      margin-top: -5px;
    }
  }

}

.ui.menu.sub-main-menu {
  margin-top: 0;
  box-shadow: none;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*.overlay {
  float: left;
  margin: 0em 3em 1em 0em;
}
.overlay .menu {
  position: relative;
  left: 0;
  transition: left 0.5s ease;
}*/

.main.menu.fixed {
  background-color: #FFFFFF;
  border: 1px solid #DDD;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
/*.overlay.fixed .menu {
  left: 800px;
}*/

/*.text.container .left.floated.image {
  margin: 2em 2em 2em -4em;
}
.text.container .right.floated.image {
  margin: 2em -4em 2em 2em;
}*/

.ui.footer.segment {
  margin: 4em 0em 0em;
  padding: 4em 0em;
}

.segment.center iframe {
  margin: 0 auto;
}

.search-widget {
  iframe.sletat-frame.module5-iframe {
    margin-top: -1.6em;
  }
  iframe.sletat-frame:not(.module5-iframe) {
    margin-top: auto;
  }
}

.news-feed.ui.feed {
  &>.event>.label img {
    object-fit: cover;
    height: 40px;
    width: 40px;
  }
  &>.event>.content .date {
    margin: -0.8rem 0 0;
  }
}

.news-list-container {
  .ui.cards .image img {
    max-height: 240px;
    object-fit: cover;
  }
}

.news-container {
  background-color: #fff;
  border-radius: .264rem;
  padding: 1rem;

  h2.ui.header {
    margin-top: .3rem;
  }

  .ui.breadcrumb {
    padding: .5rem 0;
  }

  .news-body {
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      clear: both;
    }
  }
}

.about-container {
  .about-team-tab {
    .ui.card .image img {
      height: 240px;
      object-fit: cover;
    }
  }
}


.old {
  .container-wrapper {
    min-height: ~'calc(100vh - 200px)';
  }

  .slick-list {
    padding-bottom: 5px;
  }

  .card-action {
    min-height: 20px;
  }

  .pics-container {

    .material-placeholder {
      display: inline-block;
    }
  }

  .preloader-wrapper.big {
    margin-left: 45%;
    margin-top: 10px;
    width: 80px;
    height: 80px;
  }

  .big-input {
    margin-top: 15px;
    input {
      margin-top: 6px;
    }
    label {
      margin-top: 5px;
      &.active {
        margin-top: 0;
      }
    }
  }

  .hot-tour--small {
    max-height: 300px;
    overflow: auto;
  }

  .post-content--small {
    max-height: 300px;
    overflow: hidden;
  }

  nav {
    background-color: rgba(255, 255, 255, 0.85);
    position: fixed;
    z-index: 100;
    box-shadow: none;

    li > a {
      text-transform: uppercase;
      color: #292929;
    }

    .menu-btn {
      margin-left: 10px;
      margin-right: 10px;
      &:hover {
        background-color: transparent;
      }
    }

    .menu-social-btn {
      &:hover {
        background-color: transparent;
      }

      a {
        padding: 0 5px 0 5px;
        height: 64px;
      }

      svg {
        height: 35px;
        width: 35px;
        margin-top: 15px;
      }

      &.social-btn-vk {
        svg {
          fill: #45668e;
        }
      }

      &.social-btn-fb {
        svg {
          fill: #3b5998;
        }
      }
    }

    .menu-logo {
      &:hover {
        background-color: transparent;
      }
      a {
        cursor: pointer;
      }
    }
  }

  .news-container {
    .card {
      .card-title {
        line-height: 30px;
        margin-bottom: 20px;
      }
      .card-image {
        background-size: cover;
        background-position: 50%;
        height: 260px;
      }
    }

    .news-item {
      margin-bottom: 40px;

      .news-body {
        ul li {
          list-style-type: disc;
          margin-left: 20px;
        }
      }
    }

    .news-share {
      margin-top: -8px;
      text-align: center;

      .color-vk {
        background-color: #45668e;
      }
      .color-fb {
        background-color: #3b5998;
      }
      .color-tw {
        background-color: #00aced;
      }
      .color-ok {
        background-color: #f6881f;
      }
      .color-gp {
        background-color: #dd4b39;
      }

      .fa {
        font-size: 1.2rem;
        vertical-align: middle;
      }
    }
  }

  .pics-container {
    text-align: center;
  }

  .cover {
    position: relative;
    z-index: 1;
    background-size: cover;
    background: #fcfcfc no-repeat 50% 50%;
    min-height: 400px;

    &.cover--small {
      min-height: 200px;
      height: 200px;
    }

    .image-copyright {
      position: absolute;
      bottom: 0;
      left: 10px;
      font-size: 30px;
      color: #fff;
      z-index: 10;
    }

    .logo span {
      display: inline-block;
      font-size: 1.1em;
      position: relative;
      bottom: 10px;
      margin-left: 2px;
      color: #fff;
      vertical-align: super;
      font-weight: 700;
    }
  }

  .cover-title {
    color: #fff;
    margin-top: 130px;
  }

  .big-logo {
    position: absolute;
    top: 20%;
    left: 50%;
    margin: 0 auto 0 -150px;

    .logo-inner {
      position: relative;
      width: 300px;

      img {
        height: 200px;
      }
    }

    span {
      color: #fff;
      font-size: 34px;
      font-weight: 300;
      line-height: 1.1;
      display: inline-block;
      top: 90px;
      left: 80px;
      position: absolute;

      &:after {
        content: "туристическая компания";
        display: block;
        font-size: 0.41em;
        position: relative;
        bottom: 5px;
        margin-left: 2px;
      }
    }
  }

  .tubs-container {
    width: 100%;
    position: absolute;
    bottom: 10px;

    a {
      display: inline-block;
      height: 200px;
      width: 200px;
      padding: 80px 10px;
      margin: 10px;

      font-size: 1.3rem;
      text-transform: uppercase;
      text-decoration: none;
      opacity: .85;
      color: #fff;

      box-sizing: border-box;
      border-radius: 50%;
      line-height: 22px;
      vertical-align: middle;

      transition: all .3s ease-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .contacts-container {

    .contacts-wrapper {
      position: relative;
      margin-top: 40px;
      margin-bottom: 40px;

      .contacts-info {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 60%;
        height: 600px;
        background-color: rgba(0, 137, 123, 0.90);
        padding: 100px 10%;

        h3 {
          color: #fff;
        }
        h5 {
          font-weight: 300;
          font-size: 20px;
          line-height: 24px;
        }

        address {
          font-style: normal;
          color: #fff;
        }
      }
    }
  }

  @supports (flex-wrap: wrap) {
    .tubs-container {
      display: flex;
      flex-flow: row wrap;
      align-content: space-between;
      align-items: center;
      justify-content: center;

      .flex-block {
        flex-basis: 200px;
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
        max-width: 200px;
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    .tubs-container {
      .flex-block {
        flex-basis: 140px;
        flex-grow: 1;
        flex-shrink: 1;
        width: auto;
        max-width: 140px;
        text-align: center;
      }

      a {
        height: 140px;
        width: 140px;
        padding: 50px 10px;

        font-size: 1rem;
        line-height: 20px;
      }
    }
  }

  .teamlist {
    .person {
      margin-bottom: 40px;

      .photo {
        display: inline-block;
        width: 250px;
        height: 250px;
        background: #eee 50%;
        background-size: cover;
        border-radius: 50%;
      }

      .name {
        font-weight: 300;

      }
    }
  }

  .find-us {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;

    .contacts {
      position: absolute;
      box-sizing: border-box;
      z-index: 10;
      top: 0;
      left: 0;
      width: 50%;
      height: 600px;
      background-color: rgba(0, 137, 123, 0.90);
      display: block;
      padding: 100px 10%;

      h3 {
        color: #fff;
      }
      h5 {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
      }

      address {
        font-style: normal;
        color: #fff;
      }
    }
  }

  footer {
    display: block;
    padding: 35px 10% 30px;
    box-sizing: border-box;

    a {
      color: #BEC3C7;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700;
      transition: all 0.3s ease-out;

      &:hover {
        color: #d4d4d4;
      }
    }

    li {
      list-style: none;
    }

    .footer-info {
      &.footer-info--hidden {
        display: none;
      }
    }

    .footer-nav {
      width: 59.2%;
      text-align: center;
      display: inline-block;

      .btn-to-top {
        margin-left: 15px;
        margin-bottom: -14px;
      }
    }

    .home, .social {
      font-size: 1.4em;
      vertical-align: middle;
      width: 18%;
      display: inline-block;
      text-align: left;
    }

    .social {
      text-align: right;
      padding-top: 5px;
    }
  }

  #admin-enter {
    .lock-icon {
      font-size: 3.5rem;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
}