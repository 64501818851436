body {
  background-color: #fcfcfc;
}
body {
  background-color: #fff;
}
.main.container {
  margin-top: 2em;
}
.cover-container {
  height: 150px;
  background-color: #9e9e9e;
  background-position: center;
}
.cover-container.small-cover {
  height: 150px;
}
.ui.menu.main-menu {
  border: none;
  box-shadow: none;
  border-radius: 0.28571429rem 0.28571429rem 0 0;
}
.ui.menu.main-menu .item img.logo {
  width: 80px;
  margin-right: 10px;
}
.ui.menu.main-menu .item .logo-brand {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.1em;
  letter-spacing: 1.4px;
}
.ui.menu.main-menu.fixed img.logo {
  width: 60px;
  margin-right: 0;
  margin-top: -5px;
}
.ui.menu.sub-main-menu {
  margin-top: 0;
  box-shadow: none;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/*.overlay {
  float: left;
  margin: 0em 3em 1em 0em;
}
.overlay .menu {
  position: relative;
  left: 0;
  transition: left 0.5s ease;
}*/
.main.menu.fixed {
  background-color: #FFFFFF;
  border: 1px solid #DDD;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
/*.overlay.fixed .menu {
  left: 800px;
}*/
/*.text.container .left.floated.image {
  margin: 2em 2em 2em -4em;
}
.text.container .right.floated.image {
  margin: 2em -4em 2em 2em;
}*/
.ui.footer.segment {
  margin: 4em 0em 0em;
  padding: 4em 0em;
}
.segment.center iframe {
  margin: 0 auto;
}
.search-widget iframe.sletat-frame.module5-iframe {
  margin-top: -1.6em;
}
.search-widget iframe.sletat-frame:not(.module5-iframe) {
  margin-top: auto;
}
.news-feed.ui.feed > .event > .label img {
  object-fit: cover;
  height: 40px;
  width: 40px;
}
.news-feed.ui.feed > .event > .content .date {
  margin: -0.8rem 0 0;
}
.news-list-container .ui.cards .image img {
  max-height: 240px;
  object-fit: cover;
}
.news-container {
  background-color: #fff;
  border-radius: 0.264rem;
  padding: 1rem;
}
.news-container h2.ui.header {
  margin-top: 0.3rem;
}
.news-container .ui.breadcrumb {
  padding: 0.5rem 0;
}
.news-container .news-body img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  clear: both;
}
.about-container .about-team-tab .ui.card .image img {
  height: 240px;
  object-fit: cover;
}
.old .container-wrapper {
  min-height: calc(100vh - 200px);
}
.old .slick-list {
  padding-bottom: 5px;
}
.old .card-action {
  min-height: 20px;
}
.old .pics-container .material-placeholder {
  display: inline-block;
}
.old .preloader-wrapper.big {
  margin-left: 45%;
  margin-top: 10px;
  width: 80px;
  height: 80px;
}
.old .big-input {
  margin-top: 15px;
}
.old .big-input input {
  margin-top: 6px;
}
.old .big-input label {
  margin-top: 5px;
}
.old .big-input label.active {
  margin-top: 0;
}
.old .hot-tour--small {
  max-height: 300px;
  overflow: auto;
}
.old .post-content--small {
  max-height: 300px;
  overflow: hidden;
}
.old nav {
  background-color: rgba(255, 255, 255, 0.85);
  position: fixed;
  z-index: 100;
  box-shadow: none;
}
.old nav li > a {
  text-transform: uppercase;
  color: #292929;
}
.old nav .menu-btn {
  margin-left: 10px;
  margin-right: 10px;
}
.old nav .menu-btn:hover {
  background-color: transparent;
}
.old nav .menu-social-btn:hover {
  background-color: transparent;
}
.old nav .menu-social-btn a {
  padding: 0 5px 0 5px;
  height: 64px;
}
.old nav .menu-social-btn svg {
  height: 35px;
  width: 35px;
  margin-top: 15px;
}
.old nav .menu-social-btn.social-btn-vk svg {
  fill: #45668e;
}
.old nav .menu-social-btn.social-btn-fb svg {
  fill: #3b5998;
}
.old nav .menu-logo:hover {
  background-color: transparent;
}
.old nav .menu-logo a {
  cursor: pointer;
}
.old .news-container .card .card-title {
  line-height: 30px;
  margin-bottom: 20px;
}
.old .news-container .card .card-image {
  background-size: cover;
  background-position: 50%;
  height: 260px;
}
.old .news-container .news-item {
  margin-bottom: 40px;
}
.old .news-container .news-item .news-body ul li {
  list-style-type: disc;
  margin-left: 20px;
}
.old .news-container .news-share {
  margin-top: -8px;
  text-align: center;
}
.old .news-container .news-share .color-vk {
  background-color: #45668e;
}
.old .news-container .news-share .color-fb {
  background-color: #3b5998;
}
.old .news-container .news-share .color-tw {
  background-color: #00aced;
}
.old .news-container .news-share .color-ok {
  background-color: #f6881f;
}
.old .news-container .news-share .color-gp {
  background-color: #dd4b39;
}
.old .news-container .news-share .fa {
  font-size: 1.2rem;
  vertical-align: middle;
}
.old .pics-container {
  text-align: center;
}
.old .cover {
  position: relative;
  z-index: 1;
  background-size: cover;
  background: #fcfcfc no-repeat 50% 50%;
  min-height: 400px;
}
.old .cover.cover--small {
  min-height: 200px;
  height: 200px;
}
.old .cover .image-copyright {
  position: absolute;
  bottom: 0;
  left: 10px;
  font-size: 30px;
  color: #fff;
  z-index: 10;
}
.old .cover .logo span {
  display: inline-block;
  font-size: 1.1em;
  position: relative;
  bottom: 10px;
  margin-left: 2px;
  color: #fff;
  vertical-align: super;
  font-weight: 700;
}
.old .cover-title {
  color: #fff;
  margin-top: 130px;
}
.old .big-logo {
  position: absolute;
  top: 20%;
  left: 50%;
  margin: 0 auto 0 -150px;
}
.old .big-logo .logo-inner {
  position: relative;
  width: 300px;
}
.old .big-logo .logo-inner img {
  height: 200px;
}
.old .big-logo span {
  color: #fff;
  font-size: 34px;
  font-weight: 300;
  line-height: 1.1;
  display: inline-block;
  top: 90px;
  left: 80px;
  position: absolute;
}
.old .big-logo span:after {
  content: "туристическая компания";
  display: block;
  font-size: 0.41em;
  position: relative;
  bottom: 5px;
  margin-left: 2px;
}
.old .tubs-container {
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.old .tubs-container a {
  display: inline-block;
  height: 200px;
  width: 200px;
  padding: 80px 10px;
  margin: 10px;
  font-size: 1.3rem;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.85;
  color: #fff;
  box-sizing: border-box;
  border-radius: 50%;
  line-height: 22px;
  vertical-align: middle;
  transition: all 0.3s ease-out;
}
.old .tubs-container a:hover {
  opacity: 1;
}
.old .contacts-container .contacts-wrapper {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
}
.old .contacts-container .contacts-wrapper .contacts-info {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 60%;
  height: 600px;
  background-color: rgba(0, 137, 123, 0.9);
  padding: 100px 10%;
}
.old .contacts-container .contacts-wrapper .contacts-info h3 {
  color: #fff;
}
.old .contacts-container .contacts-wrapper .contacts-info h5 {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}
.old .contacts-container .contacts-wrapper .contacts-info address {
  font-style: normal;
  color: #fff;
}
@supports (flex-wrap: wrap) {
  .old .tubs-container {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    align-items: center;
    justify-content: center;
  }
  .old .tubs-container .flex-block {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
    width: auto;
    max-width: 200px;
    text-align: center;
  }
}
@media only screen and (max-width: 960px) {
  .old .tubs-container .flex-block {
    flex-basis: 140px;
    flex-grow: 1;
    flex-shrink: 1;
    width: auto;
    max-width: 140px;
    text-align: center;
  }
  .old .tubs-container a {
    height: 140px;
    width: 140px;
    padding: 50px 10px;
    font-size: 1rem;
    line-height: 20px;
  }
}
.old .teamlist .person {
  margin-bottom: 40px;
}
.old .teamlist .person .photo {
  display: inline-block;
  width: 250px;
  height: 250px;
  background: #eee 50%;
  background-size: cover;
  border-radius: 50%;
}
.old .teamlist .person .name {
  font-weight: 300;
}
.old .find-us {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
}
.old .find-us .contacts {
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  top: 0;
  left: 0;
  width: 50%;
  height: 600px;
  background-color: rgba(0, 137, 123, 0.9);
  display: block;
  padding: 100px 10%;
}
.old .find-us .contacts h3 {
  color: #fff;
}
.old .find-us .contacts h5 {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}
.old .find-us .contacts address {
  font-style: normal;
  color: #fff;
}
.old footer {
  display: block;
  padding: 35px 10% 30px;
  box-sizing: border-box;
}
.old footer a {
  color: #BEC3C7;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.3s ease-out;
}
.old footer a:hover {
  color: #d4d4d4;
}
.old footer li {
  list-style: none;
}
.old footer .footer-info.footer-info--hidden {
  display: none;
}
.old footer .footer-nav {
  width: 59.2%;
  text-align: center;
  display: inline-block;
}
.old footer .footer-nav .btn-to-top {
  margin-left: 15px;
  margin-bottom: -14px;
}
.old footer .home,
.old footer .social {
  font-size: 1.4em;
  vertical-align: middle;
  width: 18%;
  display: inline-block;
  text-align: left;
}
.old footer .social {
  text-align: right;
  padding-top: 5px;
}
.old #admin-enter .lock-icon {
  font-size: 3.5rem;
  margin-top: 20px;
  margin-bottom: 40px;
}
